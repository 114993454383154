<template>
    <p>
        <router-link class="wiki-part-name clickable-wiki-part" :to="linkToArticle">
            <font-awesome-icon icon="newspaper" class="mr-1"/>
            {{ article.nom }}
            <visibility-icon-wiki :wiki-part="article"/>
        </router-link>
        <b-button pill size="sm" class="ml-2"
                  v-b-tooltip.hover="'Télécharger'" @click="downloadPdf">
            <font-awesome-icon icon="download"/>
        </b-button>
        <b-button v-if="canEditArticle" pill variant="info" size="sm"
                  v-b-tooltip.hover="'Éditer'" @click="editArticle">
            <font-awesome-icon icon="pen"/>
        </b-button>
        <b-button v-if="canDeleteArticle" pill variant="danger" size="sm"
                  v-b-tooltip.hover="'Supprimer'" @click="confirmDeleteArticle">
            <font-awesome-icon icon="trash-alt"/>
        </b-button>
    </p>
</template>

<script>
    import wiki                        from '@/util/wiki';
    import alert                       from '@/util/alert';
    import {apiPath, downloadResponse} from '@/util/http';

    const VisibilityIconWiki = () => import('./VisibilityIconWiki');
    const EditArticleModal   = () => import('@/components/modals/wiki/EditArticleModal');

    export default {
        name: "RowArticleWiki",
        components: {VisibilityIconWiki},
        props: {
            article: {
                type: Object,
                required: true
            }
        },
        computed: {
            linkToArticle() {
                return wiki.linkToArticlePage(this.article, this.$route);
            },
            canEditArticle() {
                return wiki.canEditArticle(this.article);
            },
            canDeleteArticle() {
                return wiki.canDeleteArticle(this.article);
            }
        },
        methods: {
            editArticle() {
                this.$store.dispatch('modal/create', {
                    component: EditArticleModal,
                    props: {
                        article: this.article,
                        callback: () => this.$emit('reload-tree')
                    }
                });
            },
            confirmDeleteArticle() {
                alert.confirm(
                    'Voulez-vous vraiment supprimer l\'article "' + this.article.nom + '" ? ',
                    () => this.deleteArticle()
                );
            },
            deleteArticle() {
                alert.loading();
                this.axios.delete(apiPath('wiki_article_delete', {article: this.article.id}))
                    .then(() => this.$emit('reload-tree'))
                    .catch(() => this.$toaster.error('Impossible de supprimer le dossier'))
                    .finally(alert.stopLoading);
            },
            downloadPdf() {
                alert.loading()
                    .then(() => this.axios({
                        url: apiPath('wiki_article_pdf', {article: this.article.id}),
                        method: 'GET',
                        responseType: 'blob'
                    }))
                    .then(downloadResponse)
                    .finally(alert.stopLoading);
            }
        }
    }
</script>

<style scoped>
    a {
        color: inherit !important;
    }
</style>